.building-selector-overlay {
    z-index: 100;
    position: absolute;
    left: 0;
    width: 545px;
    top: 0;
    background-color: var(--audette-white);
    border: 1px solid var(--audette-gray-300);
    border-radius: 4px;
    box-shadow: 5px 5px 16px 0px rgba(168, 143, 197, 0.25);

    &__input {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 8px;
        border-bottom: 1px solid var(--audette-gray-300);

        & > input {
            border: none;
            outline: none;
            flex-grow: 1;
            font-size: 1rem;
            font-family: "DM Sans";
        }
    }

    &__options {
        padding: 8px 0;
        max-height: 350px;
        overflow: auto;

        &__select {
            padding: 16px;
        }

        button {
            width: 100%;
        }

        p {
            width: 100%;
            text-align: left;
        }
    }
}
