.form-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);

    .form-input {
        padding: 8px;
        min-width: 210px;
        border: 1px solid var(--audette-concrete);
        border-radius: 4px;
        font-family: "DM Sans";
    }

    .form-input.error {
        border-color: var(--audette-content-negative);
    }

    .form-input:focus {
        padding: 8px;
        padding-right: 20px;
        border: 1px solid var(--audette-black);
        border-radius: 4px;
        outline: none;
    }
}

.horizontal-form {
    .form-input-wrapper {
        flex-direction: row;
    }
}