.filters-sidebar {
    &__body {
        height: 100%;
        padding-top: var(--size-margin-s);
        overflow: auto;
    }

    &__footer {
        display: flex;
        padding: var(--size-margin-l);
        justify-content: space-between;
        margin-top: auto;
        &__buttons {
            display: flex;
        }
    }
}

.filter-container {
    padding: var(--size-margin-s) var(--size-margin-m);
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);

    &__title {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__body {
        height: 0;
        transition: all 0.06s ease-out;
        overflow-y: hidden;

        &.show {
            height: fit-content;
            overflow-y: visible;
            flex-direction: row;
        }
    }
}

.filter {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 8px;
    width: 100%;

    &--between {
        display: grid;
        grid-template-columns: 2;

        & > .filter__dropdown--between {
            grid-column: span 2;
        }
    }

    &__dropdown {
        min-width: 40%;

        &.menu {
            width: 100%;
        }

        button {
            width: 100%;
        }
    }
}

.filter-input {
    flex-grow: 1;
    border-radius: 4px;
    border: 1px solid var(--audette-gray-300);
    background: white;
    height: 35px;
    padding: var(--size-margin-xs);
    min-width: 0;
}

.date-picker {
    height: 35px;
    border: none !important;
    color: var(--audette-gray-300);

    input {
        height: 18px;

        &:focus {
            border: none;
            outline: none;
        }
    }

    & > * {
        font-size: 0.875rem !important;
    }
}
