.building-page-with-sidebar {
    display: flex;
    position: relative;
    overflow-x: hidden;
}

.building-page {
    display: flex;
    flex-direction: column;
    min-width: 0;
    width: 100%;
}

.baseline-building-report-header {
    padding: var(--size-margin-m);
    display: flex;
    justify-content: space-between;
}

.baseline-building-report-header__menu-items {
    display: flex;
    gap: 8px;
    align-items: center;
    height: fit-content;
}

.building-name-and-tags {
    display: flex;
    align-items: center;
    gap: 8px;
}

.building-header-property-and-location {
    display: flex;
    align-items: center;
    gap: 16px;
}

.baseline-building-report-header--building-info {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);
}

.building-page--view-tabs {
    margin: 0 var(--size-margin-m);
    display: flex;
    gap: var(--size-margin-s);
    overflow-x: auto;
    min-height: fit-content;
    
    .view-tab{
        width: max-content;
    }
}

.building-page .report {
    border-top: 1px solid var(--audette-gray-300);
    background-color: var(--audette-gray-100);
}

.building-name-select {
    text-align: left;
    color: var(--audette-pink);
}

.building-name-select:hover {
    color: var(--audette-black);
}

.delete-building-button-container {
    position: relative;
}

.delete-building-popup {
    position: absolute;
    text-wrap: nowrap;
    top: calc(var(--size-margin-l) + var(--size-margin-m) / 2);
    right: 0;
    gap: 8px;
    color: var(--audette-content-negative);
    padding: var(--size-margin-m);
    background-color: var(--audette-white);
    box-shadow: 5px 5px 16px 0px rgba(168, 143, 197, 0.25);
    border-radius: 4px;
}

.empty-building-report {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-basis: 100%;
    border-top: 1px solid var(--audette-gray-300);
    background-color: var(--audette-gray-100);

    &__title {
        display: flex;
        flex-direction: column;
        gap: var(--size-margin-m);
        align-items: center;
    }
}
