.alert {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    min-height: 40px;
    min-width: 500px;
    z-index: 1000;
    display: flex;
    gap: var(--size-margin-s);
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 5s, opacity 0.5s ease;

    svg {
        flex-shrink: 0;
    }

    p:nth-child(2) {
        margin-right: auto;
    }

    &.success {
        background-color: #ccfae5;
        border: 1px solid #3b814b;
    }

    &.error {
        background-color: #ffd1d0;
        border: 1px solid #cc303c;
    }

    &.show {
        animation: slideIn 0.5s forwards;
        visibility: visible;
    }
}

@keyframes slideIn {
    from {
        top: -50px;
        opacity: 0;
    }
    to {
        top: 10px;
        opacity: 1;
    }
}
