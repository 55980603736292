.modal {
    position: absolute;
    top: 30%;
    right: calc(50% - 250px);
    background-color: var(--audette-white);
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-l);
    padding: var(--size-margin-m);
    border-radius: 4px;

    /* Container shadow */
    box-shadow: 5px 5px 16px 0px rgba(168, 143, 197, 0.26);

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__body {
        display: flex;
    }

    &__footer {
        display: flex;
        justify-content: flex-start;
    }
}
