.decarbonization-building-report {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-m);
    margin: var(--size-margin-m);
}

.recommendations-report--title {
    display: flex;
    align-items: baseline;
    gap: 4px;
}
