.buildings-page {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    min-height: 0;

    & > .view-body {
        flex-basis: 100%;
        height: 300px;

        .table-view {
            padding-top: 0;
            min-width: 0;
            min-height: 0;
        }

        .emission-intensity-map {
            height: 100%;
        }
    }
}

.bulk-edit-tags {
    display: grid;
    gap: 16px;
    width: 100%;

    &__select {
        padding: 8px 12px;
        border-radius: 4px;
        min-height: 35px;
        border: 1px solid var(--audette-gray-300);

        & button {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            height: 100%;
            width: 100%;
        }
    }
    & > .building-add-tags-overlay {
        width: 100%;
    }

    &__footer {
        display: flex;
        width: 100%;

        & > *:nth-child(3) {
            margin-left: auto;
        }
    }
}

.add-new-property {
    &__button {
        display: flex;
        gap: 8px;
    }

    &__modal__footer {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        width: 100%;

        & > *:nth-child(2) {
            margin-right: auto;
        }
    }

    &__modal-body {
        display: grid;
        gap: var(--size-margin-l);

        width: 100%;
        .form-input-wrapper {
            width: 100%;
        }

        &__buildings {
            display: flex;
            flex-wrap: wrap;
            border-radius: 4px;
            border: 1px solid var(--audette-gray-300);
            background: var(--audette-white);
            height: 35px;
            padding: 0 8px;
            align-items: center;
            gap: 8px;

            .tag {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 4px 8px;
                border-radius: 8px;
                background-color: #efe4ff;
            }
        }
    }
}
