.sidebar-loading {
    width: 100%;
    overflow: hidden;

    &__section {
        padding: var(--size-margin-m);
        display: flex;
        flex-direction: column;
        gap: 32px;

        &__header {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            padding: 9px 0;
            & > div {
                display: flex;
                gap: 8px;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: 24px;

            & > div {
                display: flex;
                gap: 8px;
            }
        }
    }
}
