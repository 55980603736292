.properties-page {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    min-height: 0;
}

.property-address-tooltip {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    gap: var(--size-margin-s);

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
