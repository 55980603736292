.hoverableDropdown {
    position: relative;

    p {
        width: 100%;
        text-align: left;
    }

    button {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .tab{
        min-width: unset;
    }
}

.menuContainer {
    position: absolute;
    min-width: 400px;
    right: calc(-1 * var(--size-margin-s));
    opacity: 1;

}


.dropdown {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);
    background-color: var(--audette-white);
    margin: 8px;
    padding: var(--size-margin-s);
    border-radius: 4px;
    box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%);
    align-items: flex-start;

}

.arrow {
    position: absolute;
    right: var(--size-margin-m);
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--audette-black);
}
