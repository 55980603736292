.bottomMargin{
    margin-bottom: var(--size-margin-l);
}

.organizationContainer{
    margin-left: auto;
    margin-right: auto;

    display: flex;
    padding: var(--size-margin-m);
    flex-direction: column;
    gap: var(--size-margin-m);

    /* style */
    border-radius: 8px;
    border: 1px solid var(--audette-gray-300);
    background: var(--audette-white);

    div {
        display: flex;
        gap: var(--size-margin-s);
        align-items: center;
    }
}