.sandbox-container {
    padding: 2rem;

    .sandbox-header {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #eee;

        h1 {
            color: #666;
            font-size: 1.5rem;
        }
    }

    .sandbox-item {
        padding: 1rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        background: #fff;
    }
}

.sandbox-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background: #0056b3;
    }
}
