.custom-plan-report {
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .measures-projects-report-table {
        table {
            border-collapse: separate;
        }
        
        tr {
            th,
            td {
                &:first-child {
                    padding-left: var(--size-margin-m);
                    position: sticky;
                    left: 0;
                    background-color: var(--audette-white);

                    span {
                        width: 70px;
                    }
                }

                &:nth-child(2) {
                    position: sticky;
                    left: 106px;
                    box-sizing: border-box;
                    border-right: 1px solid var(--audette-gray-300);
                }
            }

            td {
                &:first-child,
                &:nth-child(2) {
                    background-color: var(--audette-white);
                }
            }

            th {
                z-index: 1;

                &:first-child,
                &:nth-child(2) {
                    background-color: var(--audette-gray-100);
                }
            }

            &:hover {
                td {
                    background-color: #f7f1fe !important;
                }
            }
        }
    }
}