html {
    --audette-black: #000;
    --audette-white: #fff;
    --audette-concrete: #e3e5de;
    --audette-lime: #39b54a;
    --audette-pink: #eb03ad;
    --audette-purple: var(--audette-purple-300);
    --audette-purple-100: #d5bbf9;
    --audette-purple-200: #a467fa;
    --audette-purple-300: #70f;
    --audette-purple-400: #7a2aeb;
    --audette-pink-100: #f7c1ec;
    --audette-pink-200: #ef81da;
    --audette-pink-400: #c10098;
    --audette-orange-300: #f94646;
    --audette-beige-300: #f7931e;
    --audette-orange-200: #f9a9a7;
    --audette-lime-200: #50e253;
    --audette-gray-100: #f6f6f6;
    --audette-gray-200: #eee;
    --audette-gray-300: #e1e1e1;
    --audette-gray-400: #afafaf;
    --audette-gray-500: #757575;
    --audette-gray-600: #545454;
    --audette-chart-goal: #70f;
    --audette-chart-baseline: #00bc98;
    --audette-chart-recommendations: #eb03ad;
    --audette-content-negative: #cc303c;
    --audette-content-warning: #ca8727;
    --audette-content-positive: #3b814b;
    --size-half-column-width: 170px;
    --size-full-column-width: 364px; /* 2 * 170px + 24px */
    --size-two-thirds-column-width: 243px;
    --size-margin-xl: 3rem;
    --size-margin-l: 2rem;
    --size-margin-m: 1.5rem;
    --size-margin-s: 1rem;
    --size-margin-xs: 0.75rem;
    --transition-length: 0.3s;
    --nav-bar-height: 50px;
    --page-header-height: 120px;
    --table-header-height: 50px;

    /* TODO: add view header height */
    --table-view-height: calc(
        100vh - var(--page-header-height) - var(--size-margin-xl) -
            var(--nav-bar-height) - 42px
    );

    background-color: var(--audette-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* z indices */
    --z-index-dropdown: calc(var(--z-index-table) + 1);
    --z-index-spinner: 1;
    --z-index-table: 1;
    --z-index-building-sidebar: calc(var(--z-index-table) + 1);
    --z-index-header: calc(
        var(--z-index-table) + var(--z-index-reports-header) + 1
    );
    --z-index-map-legend: 1;
    --z-index-map-title: 1;

    /* add 2 because that's the z-index of mapbox controls */
    --z-index-reports-header: calc(2 + var(--z-index-map-legend));
    --z-index-sidebar: calc(var(--z-index-header) + 1);
}

* {
    margin: 0;
    box-sizing: border-box;
}

sup {
    line-height: 1em;
    font-size: 0.7em;
    vertical-align: top;
}

sub {
    line-height: 1em;
    font-size: 0.7em;
    vertical-align: bottom;
}

.viewport {
    height: calc(100vh - var(--nav-bar-height));
    width: 100%;
    display: flex;
    flex-direction: column;
}

.padded-s {
    padding: var(--size-margin-s);
}

.padded-m {
    padding: var(--size-margin-m);
}

.padded-l {
    padding: var(--size-margin-l);
}

.margin-top-s {
    margin-top: var(--size-margin-s);
}

.margin-top-m {
    margin-top: var(--size-margin-m);
}

.margin-top-l {
    margin-top: var(--size-margin-l);
}

.margin-top-xl {
    margin-top: var(--size-margin-xl);
}

.margin-bottom-s {
    margin-bottom: var(--size-margin-s);
}

.margin-bottom-m {
    margin-bottom: var(--size-margin-m);
}

.margin-bottom-l {
    margin-bottom: var(--size-margin-l);
}

.align-right {
    display: flex;
    justify-content: flex-end;
}

.align-center {
    display: flex;
    justify-content: center;
}

button {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    font-family: inherit;
}

input:focus {
    outline: none;
    border: 1px solid var(--audette-purple-200);
}

tbody {
    cursor: pointer;
}

/* grids */

.side-by-side-charts-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    gap: var(--size-margin-m);
    gap: 16px;
}

.cards-and-chart-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(30%, auto));
    gap: 16px;
}

.cards {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* animations */
.loading {
    background: linear-gradient(
            100deg,
            rgb(255 255 255 / 0%) 40%,
            rgb(255 255 255 / 50%) 50%,
            rgb(255 255 255 / 0%) 60%
        )
        var(--audette-gray-300);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
    to {
        background-position-x: -20%;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
}
