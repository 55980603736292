@font-face {
    font-family: "DMSans medium";
    src: local("DMSans"), url(./DMSans/DMSans-Medium.ttf) format("opentype");
}

@font-face {
    font-family: "DM Sans bold";
    src: local("DMSans"), url(./DMSans/DMSans-Bold.ttf) format("opentype");
}

@font-face {
    font-family: "DM Sans";
    src: local("DMSans"), url(./DMSans/DMSans-Regular.ttf) format("opentype");
}

h1,
h2,
h3,
h4 {
    font-family: "DMSans medium";
}

h1 {
    font-size: 1.5rem;
    line-height: 100%;
    font-weight: 500;
    letter-spacing: -0.48px;
}

h2 {
    font-size: 1.25rem;
    line-height: 100%;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.2px;
}

h3 {
    font-size: 1rem;
    line-height: 100%;
}

h3.medium {
    font-weight: 500;
}

h3.bold {
    font-family: "DM Sans bold";
}

h4 {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 100%;
}

h2.all-caps {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.84px;
    text-transform: uppercase;
}

p {
    font-family: "DM Sans";
}

p.regular {
    font-size: 1rem;
}

p.small {
    font-size: 0.875rem;
}

p.x-small {
    font-size: 0.75rem;
}
