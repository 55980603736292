.chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .margin-right-auto {
        margin-right: auto;
        margin-top: 2px;
    }

    .dropdown-with-items {
        padding-left: 1rem;

        .single-select-dropdown{
            top: 1px;
            right: 5px;
        }

        &.energy-emission {
            padding-left: 0;
            
            .selector { 
                border: none;
                background: transparent;
                
                .small {
                    border-bottom: 2px solid black;
                    font-family: DMSans medium;
                    font-weight: 500;
                    font-size: unset;
                }
            }
        }
    }
}

.chart-header--icons {
    display: flex;
    gap: 24px;
}

.chart-header-container{
    margin-right: auto; 
    padding-left: 1rem; 
}