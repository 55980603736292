.customPlanHeader{
    display: flex;
    gap: var(--size-margin-s);
    padding: var(--size-margin-s);
    justify-content: flex-end;

    
    .deleteCustomPlanButton{
        color: var(--audette-content-negative);
        display: flex;
        gap: 7px;
    }
}