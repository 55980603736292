.by-building-bar-chart-tooltip {
    /* layout */
    display: inline-flex;
    padding: var(--size-margin-s);
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    /* styling */
    border-radius: 4px;
    border: 1px solid var(--audette-gray-100);
    background: var(--audette-white);

    /* Container shadow */
    box-shadow: 5px 5px 16px 0 rgb(168 143 197 / 26%);
}

.bar-tooltip {
    background-color: white;
    padding: var(--size-margin-s);
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 320px;
}

.bar-tooltip .audette-button {
    height: fit-content;
}

.bar-tooltip--title {
    display: flex;
    gap: 8px;
}

.bar-tooltip--row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.bar-tooltip .audette-button {
    width: 100%;
    padding: 0;
}
