.data-coverage-line {
    display: flex;
    height: 4px;
    border-radius: 4px;
    width: 100%;
    background-color: #e1e1e1;
    overflow: hidden;

    & > .segment {
        height: 100%;

        &.green {
            background-color: #00bc98;
        }

        &.purple {
            background-color: var(--audette-purple);
        }

        &.yellow {
            background-color: #f7931e;
        }

        &.red {
            background-color: #cc303c;
        }

        &.grey {
            background-color: #e1e1e1;
        }
    }
}

.data-coverage-line-tooltip {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    gap: var(--size-margin-s);

    & > div {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;

        .color-block {
            height: 16px;
            width: 16px;
            border-radius: 4px;

            &--green {
                background-color: #00bc98;
            }

            &--purple {
                background-color: var(--audette-purple);
            }

            &--yellow {
                background-color: #f7931e;
            }

            &--red {
                background-color: #cc303c;
            }

            &--grey {
                background-color: #e1e1e1;
            }
        }

        & > *:nth-child(2) {
            margin-right: auto;
        }
    }
}
