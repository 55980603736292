.card {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-m);
    border-radius: 8px;
    border: 1px solid var(--audette-gray-300);
    background: var(--audette-white);
    padding: var(--size-margin-m);
}

.card.loading {
    border: 1px solid var(--audette-gray-300);
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);
    align-items: center;
}

.card .loading-title {
    width: 30%;
    height: 12px;
    border-radius: 30px;
    background-color: var(--audette-gray-300);
}

.card .loading-body {
    width: 70%;
    height: 12px;
    border-radius: 30px;
}

.card__header {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);
    align-items: center;
}

.card__body {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);
}

.card__divider {
    height: 1px;
    background-color: var(--audette-gray-300);
}

.card__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card__title-tooltip {
    display: flex;
    gap: 8px;
    align-items: center;
}

p .percentage {
    color: var(--audette-gray-500);
    display: inline-block;
    margin-left: 8px;
}

.card__value-and-percentage {
    display: flex;
    gap: 8px;
}

.card__increase-decrease-percentage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
