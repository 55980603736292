.table-loading {
    padding: var(--size-margin-l) var(--size-margin-m);

    & > *:first-child {
        margin-bottom: var(--size-margin-l);
    }

    &__header {
        display: flex;
        gap: 6px;
        margin: 12px;

        & > *:first-child {
            margin-right: auto;
        }

        > * {
            height: 12px;
            border-radius: 30px;
        }
    }

    &__table {
        display: flex;
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        min-width: 0;

        & > *:first-child {
            background-color: #f6f6f6;
        }

        &__cell > * {
            height: 12px;
            border-radius: 30px;
            margin: 1rem;
            display: flex;
        }
    }
}
