.building-equipment-sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &--survey-view {
        position: absolute;
        top: 0;
        z-index: var(--z-index-header);
        width: 100%;
        min-height: 100%;

        &__header {
            display: flex;
            flex-direction: row;
            position: sticky;
            top: 0;
            justify-content: space-between;
            padding: var(--size-margin-m);
            background-color: var(--audette-white);
            border-bottom: 1px solid var(--audette-gray-300);
        }
    }

    &__empty-view {
        display: flex;
        flex-direction: column;
        gap: var(--size-margin-m);
        text-align: center;
        align-items: center;
        margin: 0 50px;
        position: absolute;
        top: 30%;
    }

    &__footer {
        position: sticky;
        bottom: 0px;
        left: 0px;
        background-color: var(--audette-white);
        display: flex;
        gap: 8px;
        padding: var(--size-margin-m);
        border-top: 1px solid var(--audette-gray-300);
    }

    .building-equipment-header {
        display: flex;
        align-items: center;
        margin: var(--size-margin-l);
        margin-left: 24px;
        gap: 8px;

        & > *:first-child {
            margin-right: auto;
        }

        & > button {
            min-width: 100px;
        }
  }

    .missing-natural-gas-header {
        display: flex;
        align-items: center;
        margin: var(--size-margin-s);
        margin-left: 24px;
    }
}

.building-equipment-survey-view {
    display: flex;
    flex-direction: column;
    padding: var(--size-margin-m);
    gap: var(--size-margin-m);
    flex-grow: 1;
    background-color: var(--audette-gray-100);

    &__section {
        display: flex;
        flex-direction: column;
        gap: var(--size-margin-m);

        &__radio-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        &:last-child {
            border-bottom: none;
        }

        &--size-and-year-survey {
            padding-bottom: var(--size-margin-l);
            border-bottom: 1px solid var(--audette-gray-300);
        }

        .check-box-with-select {
            display: flex;
            flex-direction: column;
            gap: var(--size-margin-s);

            &__selected {
                padding-bottom: var(--size-margin-s);
                border-bottom: 1px solid var(--audette-gray-300);
            }

            &__radio {
                display: flex;
                align-items: center;
                gap: 8px;
                padding-left: var(--size-margin-m);

                > span {
                    padding: 0;
                }
            }
        }
    }
}

.equipment-section {
    display: flex;
    flex-direction: column;
    padding: var(--size-margin-s) 0;
    margin: 0 var(--size-margin-m);
    padding-bottom: var(--size-margin-l);
    padding-top: var(--size-margin-l);
    gap: var(--size-margin-m);

    &:first-child {
        padding-top: var(--size-margin-s);
    }

    &:not(:last-child) {
        border-bottom: 1px solid var(--audette-gray-300);
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: var(--size-margin-m);
    }
}

.boolean-header-select {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 0.5rem;

    > button {
        height: 43px;
        text-align: center;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--audette-gray-300);
        background-color: var(--audette-white);
    }

    > button.focus {
        background-color: #f7f1fe;
        border: 1px solid var(--audette-purple);
    }
}
