.portfolio-page {
    display: flex;
    flex-direction: column;
    padding: var(--size-margin-l) var(--size-margin-m);
    height: 100%;

    .emission-intensity-map {
        border-radius: 0 0 8px 8px;
    }

    & > .page-header {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: var(--size-margin-l);

        & > *:first-child {
            margin-right: auto;
        }
    }

    .view-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--size-margin-m);

        &__tabs {
            display: flex;
            gap: var(--size-margin-m);
        }

        & > *:first-child {
            margin-right: auto;
        }

        & > button, #delete-buildings-button {
            display: flex;
            gap: 8px;
            height: fit-content;
            align-items: center;
            color: var(--audette-gray-600);
        }

        #delete-buildings-button {
            color: var(--audette-content-negative);
        }
    }
}
