.building-tags {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    &__tag {
        display: flex;
        min-height: 24px;
        padding: 0 8px;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: #efe4ff;
        border-radius: 8px;

        & p {
            white-space: nowrap;
        }

        & > button {
            height: fit-content;
        }
    }
}
