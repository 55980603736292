.error-page {
    background-color: var(--audette-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: var(--size-margin-xl);
}

.error-page--text--title {
    margin-bottom: var(--size-margin-l);
}

.error-page--list,
.error-page--text--options {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-m);
}

.error-page--list li button {
    padding: 0;
    height: fit-content;
}

.error-page--list > * {
    margin-left: -15px;
}
