.building-energy-page {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-l);
    margin: var(--size-margin-l) var(--size-margin-m);

    &__header {
        display: flex;
        flex-direction: column;
        gap: var(--size-margin-s);
    }
}
