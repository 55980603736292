.cumulative-emission-savings-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--audette-gray-300);
    padding-top: 24px;
}

.savings-breakdown {
    display: flex;
    flex-direction: column;
    gap: 22px;
}
