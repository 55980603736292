.inputHeader{
    margin-bottom: var(--size-margin-s);
    font-weight: 500;
}

.firstHeader{
    margin-top: var(--size-margin-m);
    margin-bottom: var(--size-margin-m);
}

.warningButton{
    background-color: var(--audette-orange-300);
    color: var(--audette-white);
    border: none;
    padding: var(--size-margin-s) var(--size-margin-m);
    border-radius: var(--size-border-radius-m);
    cursor: pointer;
    margin-right: var(--size-margin-s);
}

.warningButton:hover{
    background-color: var(--audette-orange-300);
}