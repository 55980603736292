.error-state {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--size-margin-s);
    border-radius: 8px;
    border: 1px solid var(--audette-gray-300);
    background: var(--audette-white);
    padding: var(--size-margin-m);
}
