.emission-intensity-map,
.map-loading {
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid var(--audette-gray-300);
    border-radius: 8px;
    background-color: var(--audette-white);
    overflow: hidden;
    width: 100%;
}

.emission-intensity-map .title {
    position: absolute;
    width: 100%;
    padding: var(--size-margin-m);
    background: linear-gradient(
        180deg,
        var(--audette-white) 30%,
        rgb(255 255 255 / 0%) 100%
    );
    z-index: var(--z-index-map-title);
}

.gm-ui-hover-effect {
    right: 2px !important;
    top: 2px !important;
}

.building-card {
    max-width: 250px;
}

.gm-style-iw-d {
    display: inline;
}

.gm-style .gm-style-iw-c {
    padding: 0;
}

.map-container {
    height: 100%;
    border-radius: inherit;
}

.marker {
    background-size: cover;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    border: white 1px solid;
}

.popup-node {
    background-color: transparent;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    padding: 0 !important;
    border-radius: 0 !important;
}

.map-legend {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    max-width: 320px;
    position: absolute;
    bottom: var(--size-margin-m);
    left: var(--size-margin-m);
    background-color: var(--audette-white);
    box-shadow: 0 0 8px 0 rgb(168 143 197 / 25%);
    z-index: var(--z-index-map-legend);
    gap: 8px;
    border-radius: 4px;
    opacity: 0.9;
    padding: var(--size-margin-xs);
    color: var(--audette-gray-500);
}

.map-legend--scale {
    width: 100%;
    border-radius: 2px;
    height: 8px;
    background: linear-gradient(
        90deg,
        #00bc98 0%,
        #f7931e 48.96%,
        #cc303c 100%
    );
}

.map-legend--values {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.map-legend-loading {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 55%;
    background-color: var(--audette-white);
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    box-shadow: 5px 5px 16px 0 rgb(168 143 197 / 25%);
    border-radius: 4px;
    opacity: 0.9;
}

.map-legend-loading--text__title {
    border-radius: 17.5px;
    width: 30%;
    height: 12px;
}

.map-legend-loading--text__body {
    border-radius: 17.5px;
    width: 100%;
    height: 12px;
}

.map-popup {
    display: flex;
    flex-direction: column;
    background-color: var(--audette-white);
    gap: var(--size-margin-xs);
    padding: var(--size-margin-xs);
    border-radius: 4px;
}

.map-popup--title {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--size-margin-xs);
}

.map-popup--title > button {
    height: 1.5em;
}

.map-popup--body {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
