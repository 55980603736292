.footprints-map-select-draw-tabs {
    & > *,
    button {
        width: 100%;
    }
}

.map-widgets {
    position: absolute;
    bottom: 24px;
    right: 24px;
    display: flex;
    gap: 8px;
    z-index: 100000;
}

#trash-button {
    background-color: var(--audette-white);
    padding: 6px;
    border-radius: 4px;
    height: 31px;
}

#footprint-area-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px var(--size-margin-s);
    border-radius: 4px;
    background-color: var(--audette-white);
}
