.dangerous-button {
    position: relative;

    & > .popup {
        position: absolute;
        text-wrap: nowrap;
        top: calc(var(--size-margin-l) + var(--size-margin-m) / 2);
        right: 0;
        gap: 8px;
        color: var(--audette-content-negative);
        padding: var(--size-margin-m);
        background-color: var(--audette-white);
        box-shadow: 5px 5px 16px 0px rgba(168, 143, 197, 0.25);
        border-radius: 4px;

        &--primary {
            p {
                color: var(--audette-black);
            }
        }

        &--primary-negative {
            p {
                color: var(--audette-content-negative);
            }
        }
    }
}
