.variable-width-chart-tooltip {
    display: flex;
    flex-direction: column;
    background-color: var(--audette-white);
    border-radius: 4px;
    padding: var(--size-margin-s);
    gap: 8px;
}

.color-block {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

.variable-width-chart-tooltip--title {
    display: flex;
    align-items: center;
    gap: 8px;
}
