.measures-projects-report,
.measures-measures-report {
    height: 650px;
    flex-grow: 1;

    tbody {
        background-color: white;
    }

    .projects-report-table,
    .measures-report-table {
        border-top: 1px solid var(--audette-gray-300);
    }

    table {
        border-collapse: separate;
    }

    tr {
        th,
        td {
            &:first-child {
                padding-left: var(--size-margin-m);
                position: sticky;
                left: 0;
                background-color: var(--audette-white);

                span {
                    width: 260px;
                }
            }

            &:last-child {
                padding-right: var(--size-margin-m);
            }

            &:nth-child(2) {
                position: sticky;
                left: 296px;
                box-sizing: border-box;
                border-right: 1px solid var(--audette-gray-300);
            }
        }

        td {
            &:first-child,
            &:nth-child(2) {
                background-color: var(--audette-white);
            }
        }

        th {
            z-index: 1;

            &:first-child,
            &:nth-child(2) {
                background-color: var(--audette-gray-100);
            }
        }

        &:hover {
            td {
                background-color: #f7f1fe !important;
            }
        }
    }
}
