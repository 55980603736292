.measures-page-with-sidebar {
    display: flex;
    position: relative;
    overflow-x: hidden;
}

.measures-page {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    min-width: 0;
    width: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: var(--size-margin-m);
    }
}

.measures-header-property-and-location {
    display: flex;
    align-items: center;
    gap: 16px;
}

.measures-page--view-tabs {
    margin: 0 var(--size-margin-m);
    display: flex;
    gap: var(--size-margin-s);
    justify-content: space-between;
    align-items: center;

    & > button {
        display: flex;
        gap: 0.5rem;
    }
}

.measures-name-select:hover {
    color: var(--audette-black);
}

.empty-measures-report {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-basis: 100%;
    border-top: 1px solid var(--audette-gray-300);
    background-color: var(--audette-gray-100);

    &__title {
        display: flex;
        flex-direction: column;
        gap: var(--size-margin-m);
        align-items: center;
    }
}

.measures-page-filter-with-export{
    display: flex;
    justify-content: space-between;
    gap: var(--size-margin-m);
    align-items: center;
}

.measures-page-flex-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
}
