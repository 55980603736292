.settingsPage {
    display: flex;
    flex-direction: column;
    background-color: var(--audette-gray-100);
    flex-grow: 1;
    

    .pageHeader {
        background-color: white;
        padding: var(--size-margin-m);
        padding-bottom: 0;
    }

    .settingsContent {
        background-color: var(--audette-gray-100);
        padding: var(--size-margin-m);

        .bottomMargin, .settingsTable{
            margin-bottom: var(--size-margin-l);
        }
    }

    .settingsTabs {
        padding-top: var(--size-margin-s);
    }

    .settingsTable {
        flex-grow: 1;
        border: 1px solid var(--audette-gray-300);
        border-collapse: collapse;
        overflow: hidden;
        border-radius: 8px;

        td {
            background-color: white !important;
        };

        tr:hover td {
            background-color: #f7f1fe !important;
        }
    }

    .settingsContent{
        margin-left: auto;
        margin-right: auto;
        max-width: 1300px;
    }

    .formInputWrapper{
        display: flex;
        width: 100%;
        gap: 10px;
        margin-bottom: var(--size-margin-m);

        .formInput{
            flex-grow: 1;
            border-radius: 4px;
            padding: 7px;
        }
    }

    .formInput, .settingsTable{
        border: 1px solid var(--audette-gray-300);

    }

    .organizationContainer{
        display: flex;
        padding: var(--size-margin-m);
        flex-direction: column;
        gap: var(--size-margin-m);
        padding: 0 50px;
        border-radius: 8px;
        border: 1px solid var(--audette-gray-300);
        background: var(--audette-white);
    
        div {
            display: flex;
            gap: var(--size-margin-s);
            align-items: center;
        }
    }
}


.tooltip{
    margin: 10px 5px;

    p {
        padding: 5px 5px;
    }
}