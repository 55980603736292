.data-coverage-header {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);
    padding: var(--size-margin-s);
    border: 1px solid var(--audette-gray-300);
    border-radius: 8px;
    margin-bottom: var(--size-margin-l);

    &__warning-icon {
        display: flex;
        height: 25px;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #fff0c7;
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: var(--size-margin-s);
    }

    &__item-wrapper {
        display: flex;
        flex-direction: column;
        padding: 8px;
        gap: 0.5rem;
        flex-grow: 1;
        border-radius: 8px;
    }

    &__item {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    &__item-title {
        display: flex;
        gap: 4px;
        align-items: center;
    }
}
