.reports-header {
    display: flex;
    position: sticky;
    background-color: var(--audette-white);
    top: 0;
    left: 0;
    flex-direction: column;
    padding: var(--size-margin-m);
    padding-bottom: 0;
    gap: var(--size-margin-s);
    z-index: var(--z-index-reports-header);
}

.reports-header .title {
    & > *:first-child {
        margin-right: auto;
    }

    & > *:last-child {
        margin-left: 8px;
    }

    display: flex;
}

.reports-header .filters {
    display: flex;
    gap: 8px;
    align-items: center;
}

.reports-header .goal-picker {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
}

.carbon-ratio-picker,
.year-picker {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--audette-gray-300);
    width: 97.5px;
}

.report-tabs {
    display: flex;
    gap: var(--size-margin-s);
}

.report-filter-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    border: 1px solid var(--audette-purple);
    color: var(--audette-purple);
    border-radius: 4px;
    padding: var(--size-margin-s);
}
