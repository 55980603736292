.nav-bar {
    background-color: var(--audette-purple-400);
    height: var(--nav-bar-height);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 0 var(--size-margin-l);
    gap: var(--size-margin-l);
}

.user-settings {
    display: flex;
    justify-content: center;
    color: var(--audette-white);
    border: 1px solid var(--audette-white);
    border-radius: 100%;
    width: 24px;
    height: 24px;
    align-items: center;
}

.user-settings .small {
    text-align: center;
}

.nav-bar__user-controls {
    display: flex;
    align-items: center;
    gap: var(--size-margin-m);
    z-index: var(--z-index-header);
}

.tab {
    color: var(--audette-white);
    min-width: 118px;
    height: 100%;
}

.back-button {
    display: flex;
    gap: var(--size-margin-s);
    align-items: center;
    color: var(--audette-white);
}

.tabs {
    height: 100%;
    flex-grow: 2;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.tabs h3 {
    height: 100%;
}

.tab.active {
    background-color: var(--audette-purple-200);
}

#nav-bar-sign-out-button {
    width: 100%;
}


.profile-container {
    display: flex;
    align-items: center;
    gap: 8px        ;
}

.logOutContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.logOutButton {
    justify-content: flex-start;
    padding-left: 0;
}


.headerButton {
    justify-content: flex-start;
    padding-left: 0;
    padding: var(--size-margin-s) 0;
    width: 100%;
}

.headerCustomerAccountLast {
    border-bottom: 1px solid var(--audette-gray-300);
    padding-bottom: 40px;
}