.container{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background: var(--audette-purple-300);
    overflow: hidden;
    color: white;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;

    user-select: none;
    transition: opacity 700ms, color 700ms, transform 200ms;
    cursor: pointer;
    opacity: 1;
    position: fixed;
    bottom: 31px;
    right: 31px;
    border-radius: 100%;
    font-size: 20px;
    transform: translateX(0px) translateZ(0px);
    padding-left: 13px;
}

.button{
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: center
}