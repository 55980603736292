.add-building-sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: var(--size-margin-l);
    padding: var(--size-margin-l) var(--size-margin-m);
    overflow: auto;
    background-color: var(--audette-gray-100);

    &--footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: sticky;
        bottom: 0;
        left: 0;
        border-top: 1px solid var(--audette-gray-300);
        padding: var(--size-margin-m);
        background-color: var(--audette-white);
        gap: 8px;
    }
}
