.sankey-tooltip {
    display: flex;
    gap: 40px;
    background-color: var(--audette-white);
    padding: var(--size-margin-m);
    border-radius: 4px;
    border: 1px solid var(--audette-gray-100);
}

.sankey-tooltip--rows {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.sankey-tooltip--row {
    display: flex;
    gap: 8px;
}
