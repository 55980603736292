.building-sidebar {
    // layout
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    right: 0;
    width: 0;
    height: calc(100vh - var(--nav-bar-height));
    overflow: auto;

    // style
    background-color: var(--audette-white);
    box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%);
    transition: width 0.2s ease-in-out;

    &__header {
        // layout
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        right: 0;
        z-index: var(--z-index-header);
        padding: var(--size-margin-m);
        border-bottom: 1px solid var(--audette-gray-300);

        // style
        background-color: var(--audette-white);
        border-bottom: 1px solid var(--audette-concrete);
    }

    &__row {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}

.building-sidebar.show {
    flex-basis: 420px;
    flex-shrink: 0;
}

.building-sidebar-tabs {
    display: flex;
    gap: var(--size-margin-m);
    padding: var(--size-margin-m) var(--size-margin-l);
    border-bottom: 1px solid var(--audette-gray-300);

    &__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        gap: var(--size-margin-s);
    }

    &__title-with-tag {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}

.building-energy-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    &__empty-content {
        display: flex;
        flex-direction: column;
        gap: var(--size-margin-m);
        text-align: center;
        align-items: center;
        margin: 0 50px;
        position: absolute;
        top: 35%;
    }

    .building-energy-header {
        display: flex;
        align-items: center;
        margin: var(--size-margin-m);
        margin-bottom: 0;
        gap: 8px;

        & > *:first-child {
            margin-right: auto;
        }
    }

    .energy-type-dropdown {
        & > button {
            padding: 0;
        }
    }

    .select-energy-type {
        .energy-type-inferred-icon {
            gap: 8px;
        }
    }
    }

.empty-sidebar-view {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;

    > svg {
        position: sticky;
        bottom: 0;
        left: 0;
    }
}

.energy-type-dropdown > .selector {
    border: none;
}

.building-energy-section {
    margin: var(--size-margin-m);
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-l);
    flex-grow: 1;

    &--electricity {
        padding-bottom: var(--size-margin-m);
        border-bottom: 1px solid var(--audette-gray-300);
    }

    &__header {
        display: flex;

        & > *:first-child {
            margin-right: auto;
        }

        &__title {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .section-body {
        display: flex;
        flex-direction: column;
        gap: var(--size-margin-m);
    }
}

.construction-sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__header {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: var(--size-margin-m);
        padding-bottom: var(--size-margin-l);

        &.editing {
            flex-basis: 77px;
            flex-shrink: 0;
            border-bottom: 1px solid var(--audette-gray-300);
            padding: 0 var(--size-margin-m);
        }

        > button {
            margin-left: auto;
        }
    }

    &__footer {
        position: sticky;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        border-top: 1px solid var(--audette-gray-300);
        gap: 8px;
        padding: var(--size-margin-m) var(--size-margin-m);
        background: var(--audette-white);
    }

    &.editing {
        position: absolute;
        top: 0;
        z-index: var(--z-index-header);
        height: 100%;
        width: 100%;
        padding-bottom: 0;
        background-color: var(--audette-white);
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: var(--size-margin-m);
        padding-top: 0;

        &.editing {
            background-color: var(--audette-gray-100);
            padding-top: 0;
        }

        &__header {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        &__section {
            display: grid;
            gap: var(--size-margin-m);
            padding: var(--size-margin-m) 0;

            &:first-child {
                padding-top: 0;
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--audette-gray-300);
                margin-bottom: var(--size-margin-m);
            }
        }

        &__property {
            display: flex;
            align-items: baseline;
            gap: 8px;

            &--address {
                flex-direction: column;
            }
        }
    }
}

.building-energy-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    background-color: var(--audette-white);

    padding: var(--size-margin-m);
    border-top: 1px solid var(--audette-gray-300);
}