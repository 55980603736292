.status-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    height: fit-content;
    width: fit-content;

    &--purple {
        background-color: #ede0ff;
        fill: #7700ff;
    }

    &--yellow {
        background-color: #fff0c7;
        fill: var(--audette-content-warning);
    }

    &--grey {
        background-color: var(--audette-gray-100);
        fill: var(--audette-gray-500);
    }

    &--negative {
        background-color: #ffd1d0;
        fill: var(--audette-content-negative);
    }

    &--green {
        background-color: #ccfae5;
        fill: #3b814b;
    }

    &__tooltip {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        padding: var(--size-margin-m);

        &__title {
            display: grid;
            grid-template-columns: repeat(2, auto);
            align-items: center;
            gap: 12px;
        }
    }
}
