.container {
    min-width: 90%;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    align-self: center;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    padding: 20px 0;

    p {
        max-width: 90%;
        word-wrap: break-word;
        margin-bottom: 12px;
        line-height: 24px;
        position: relative;
        padding: 10px 20px;
        border-radius: 25px;
        margin-right: 26px;
        margin-left: 26px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 25px;
        }
    }
}

:root {
    --send-color: white;
    --receive-bg: #e5e5ea;
    --receive-text: black;
}

.send {
    color: var(--send-color);
    background: var(--audette-purple);
    align-self: flex-end;

    &:before {
        right: -7px;
        width: 20px;
        background-color: var(--audette-purple);
        border-bottom-left-radius: 16px 14px;
    }

    &:after {
        right: -26px;
        width: 26px;
        background-color: var(--audette-white);
        border-bottom-left-radius: 10px;
    }
}
.receive {
    background: var(--receive-bg);
    color: black;
    align-self: flex-start;

    &:before {
        left: -7px;
        width: 20px;
        background-color: var(--receive-bg);
        border-bottom-right-radius: 16px 14px;
    }

    &:after {
        left: -26px;
        width: 26px;
        background-color: var(--audette-white);
        border-bottom-right-radius: 10px;
    }

    &:global(.loading) {
        animation: pulse 1.5s ease-in-out infinite;
        min-width: 40px;
        text-align: center;
    }
}

@keyframes pulse {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.9;
    }
    100% {
        opacity: 0.2;
    }
}

.chatTextContainer {
    padding: var(--size-margin-s) var(--size-margin-m);
    display: flex;
    gap: var(--size-margin-s);
    margin-top: auto;
    border: black;
    display: flex;
    width: 90%;
    align-self: center;
    flex-flow: nowrap;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset;
    min-height: 80px;
    background-color: transparent;
    border-radius: 18px;
    overflow: inherit;
    padding: 4px 8px 4px 12px;
    justify-content: space-between;
    margin-bottom: var(--size-margin-l);
    display: flex;
    align-items: center;
    gap: 8px;

    .settingsButton {
        background: none;
        border: none;
        cursor: pointer;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
    .chatTextInput {
        width: 100%;
        max-width: 400px;
        padding: 10px 12px;
        font-size: 14px;
        color: #333;
        background-color: #fff;
        border: none;
        border-radius: 6px;
        transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
        resize: none;
        overflow-y: hidden;
        min-height: 40px;
        max-height: 150px;

        &:focus {
            border: none;
            outline: none;
        }
    }
}
