.filter-label-card {
    display: flex;
    align-items: center;
    padding: 0 var(--size-margin-xs);
    min-height: 30px;
    border-radius: 8px;
    gap: 8px;
    background: #efe4ff;
}

.filter-label-card p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filter-name-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px 0;
    width: 100%;
}
