.chart-legend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: var(--size-margin-m);
}

.chart-legend__color {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

.chart-legend__key {
    display: flex;
    align-items: center;
    gap: 8px;
}
