.stream-chart-container {
    display: flex;
    width: 100%;
}

.stream-chart {
    min-width: 0;
    flex: 1 2 75%;
}

.annual-emission-savings-by-measure-legend {
    display: flex;
    flex-direction: column;
    padding: var(--size-margin-m);
    gap: var(--size-margin-m);
}

.annual-emission-savings-by-measure-legend .row {
    display: flex;
    gap: 8px;
}

.stream-chart-tooltip {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);
    background-color: var(--audette-white);
    padding: var(--size-margin-s);
}

.stream-chart-tooltip--row {
    display: flex;
    gap: 8px;
}
