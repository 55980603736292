.dropdown-container {
    display: flex;
    flex-direction: column;
    gap: var(--size-margin-s);
}

.single-select-dropdown {
    height: fit-content;
    position: relative;
}

.selector {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--audette-white);
    height: 35px;
    border: 1px solid var(--audette-concrete);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--size-margin-s);
    border-radius: 4px;
    min-width: 50%;
    width: 100%;
}

.menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    box-shadow: 0 9px 28px 8px rgb(0 0 0 / 15%);
    z-index: var(--z-index-dropdown);
    border-radius: 4px;
    background-color: var(--audette-white);
    width: fit-content;
}

.menu-item {
    height: 35px;
    width: 100%;
    padding: 0 var(--size-margin-s);
    display: flex;
    white-space: nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;

    /* override default button styles */
    border: none;
    outline: none;
}

.menu-item:hover {
    background-color: var(--audette-purple-100);
}

.dropdown-carrot-icon {
    transition: 0.2s ease-in-out;
    transform: rotate(0deg) !important;
    display: flex;
    align-items: center;
}

.dropdown-carrot-icon.open {
    transform: rotate(180deg) !important;
}
