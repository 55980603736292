.chart-tooltip {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--audette-white);
    background: var(--audette-white);
    padding: var(--size-margin-s);
    gap: var(--size-margin-s);
    box-shadow: 5px 5px 16px 0 rgb(168 143 197 / 26%);
}

.chart-tooltip > *:first-child {
    margin-bottom: var(--size-margin-s);
}

.chart-tooltip__row {
    display: flex;
    gap: 8px;
}

.chart-tooltip__row__color-key {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}
#scatter-plot-tooltip {
    background-color: var(--audette-white);
    padding: var(--size-margin-xs);
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(168, 143, 197, 0.25);
}

.scatter-plot {
    position: relative;
}