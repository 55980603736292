.download-banner {
    position: absolute;
    display: grid;
    gap: var(--size-margin-m);
    padding: var(--size-margin-m);
    background-color: var(--audette-white);
    width: 300px;
    bottom: var(--size-margin-m);
    right: var(--size-margin-m);
    box-shadow: 5px 5px 16px 0px rgba(168, 143, 197, 0.26);
    z-index: 1000;
    border: 1px solid var(--audette-gray-300);
    border-radius: 8px;

    &__header {
        display: flex;
        align-items: center;
        gap: 8px;

        & > button {
            margin-left: auto;
        }
    }

    &__file-name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > button {
            padding: 0;
            height: fit-content;

            & > p {
                font-size: 0.875rem;
            }
        }
    }
}

.loading-bar {
    height: 4px;
    background-color: var(--audette-pink);
    width: 0;
    transition: width 0.3s ease-in-out;
    border-radius: 4px;

    &-container {
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: var(--audette-gray-300);
    }
}
