.projects-report {
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &-table {
        table {
            border-collapse: separate;
        }

        tr {
            th,
            td {
                &:first-child {
                    padding-left: var(--size-margin-m);
                    position: sticky;
                    left: 0;
                    background-color: var(--audette-white);

                    span {
                        width: 260px;
                    }
                }

                &:last-child {
                    padding-right: var(--size-margin-m);
                }

                &:nth-child(2) {
                    position: sticky;
                    left: 296px;

                    box-sizing: border-box;
                    border-right: 1px solid var(--audette-gray-300);
                }
            }

            td {
                &:first-child,
                &:nth-child(2) {
                    background-color: var(--audette-white);
                }
            }

            th {
                z-index: 1;

                &:first-child,
                &:nth-child(2) {
                    background-color: var(--audette-gray-100);
                }
            }

            &:hover {
                td {
                    background-color: #f7f1fe !important;
                }
            }
        }
    }
}

.project-report {
    display: flex;
    flex-direction: column;
    background-color: var(--audette-gray-100);
    border-top: 1px solid var(--audette-gray-300);
    width: 100%;
    gap: var(--size-margin-l);
    padding: var(--size-margin-l) var(--size-margin-m);



    .project-report-header {
        display: grid;
        gap: var(--size-margin-m);

        &--loading {
            display: grid;
            gap: var(--size-margin-m);

            div {
                height: 12px;
                border-radius: 17.5px;
            }

            div:first-child {
                margin-bottom: 8px;
                height: 16px;
            }
        }

        &__measure_attribute {
            display: flex;
            gap: 4px;
            align-items: center;

            & > *:nth-child(2) {
                color: var(--audette-gray-600);
                margin-left: 4px;
            }
        }

        &__row {
            display: flex;
            gap: var(--size-margin-s);
            flex-wrap: wrap;
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    &__back-button {
        display: flex;
        gap: 8px;
        align-items: center;
        width: fit-content;
        cursor: pointer;
    }

    .performance-impact {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        gap: 16px;

        & > div:nth-child(2) {
            grid-row: span 2;
        }

        & > .chart-container {
            min-width: 0;
        }
    }

    .decarbonization-forecast-chart-tooltip {
        display: grid;
        gap: var(--size-margin-s);
        background-color: var(--audette-white);
        border-radius: 4px;
        box-shadow: 5px 5px 16px 0px rgba(168, 143, 197, 0.26);
        max-width: 300px;
        padding: var(--size-margin-s);

        &__header {
            display: flex;
            align-items: center;
            gap: 8px;

            &__color {
                width: 16px;
                height: 16px;
                border-radius: 4px;
                flex-shrink: 0;
            }
        }

        &__body {
            display: flex;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
        }
    }

    .financial-impact {
        .table-pagination-wrapper {
            width: 100%;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            & > :first-child {
                margin-right: auto;
            }
        }

        .small-cards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            gap: 8px;
            width: 100%;

            .card {
                display: flex;
                flex-direction: column;
                gap: var(--size-margin-m);
                border-radius: 8px;
                border: 1px solid var(--audette-gray-300);
                background: var(--audette-white);
                padding: var(--size-margin-m);
            }

            .card--small {
                display: grid;
                place-items: center;
                gap: 16px;
                height: fit-content;

                p:first-child {
                    color: var(--audette-gray-600);
                }
            }
        }

        & > div:last-child {
            display: flex;
            gap: 16px;

            & > .chart-container {
                min-width: 0;
                width: 100%;
                flex: 0 1 auto;
            }
        }
    }

    .large-card {
        display: flex;
        flex-direction: column;
        min-width: 400px;
        flex: 1 0 400px;
        gap: var(--size-margin-l);
        background-color: var(--audette-white);
        padding: var(--size-margin-m);
        border: 1px solid var(--audette-gray-300);
        border-radius: 8px;

        & > .discount-rate {
            display: flex;
            justify-content: space-between;
            color: var(--audette-gray-600);
            margin-top: auto;
        }

        &--loading {
            display: grid;
            grid-template-rows: repeat(5, min-content);
            grid-template-columns: auto 1fr;
            gap: 16px;

            div:first-child {
                grid-column: span 2;
            }

            &__grid-item {
                display: flex;

                &:nth-child(odd) {
                    justify-content: flex-end;
                }
                &:nth-child(even) {
                    justify-content: flex-start;
                }

                &:first-child {
                    grid-column: span 2;
                    justify-content: flex-start;
                }

                &:nth-child(5),
                &:first-child {
                    margin-bottom: 32px;
                }
            }
        }
    }

    .project-card-section {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__title {
            display: flex;
            align-items: center;
            gap: 8px;

            & > :last-child {
                margin-left: auto;
            }
        }

        &__body {
            display: grid;
            gap: 16px;

            & > li {
                vertical-align: middle;

                & > div {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;

                    p {
                        &:first-child {
                            margin-right: auto;
                        }

                        &:last-child {
                            color: var(--audette-gray-500);
                        }
                    }
                }
            }
        }
    }
}

.select-view-tab {
    height: 35px;
    min-width: 120px;
    border: 1px solid var(--audette-gray-300);

    &:first-of-type {
        border-radius: 4px 0 0 4px;
        border-right: none;
    }

    &:last-of-type {
        border-radius: 0 4px 4px 0;
    }

    &.selected {
        color: var(--audette-pink);
        background-color: var(--audette-white);
    }
}

.decarbonization-forecast-chart-legend {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--size-margin-l);

    &__item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: VAR(--audette-gray-600);

        &__color {
            width: 16px;
            height: 16px;
            border-radius: 4px;
        }
    }
}

.projects-report-export-button{
    margin-right: 0;
    margin-left: auto;
    margin-top: 5px;
    padding-right: 20px;
    padding-bottom: 10px;
}


.custom-plan-report .measures-projects-report-table tr {
    th:first-child span,
    td:first-child span {
        width: 70px !important;
    }
}