.chart-container {
    /* layout */
    display: flex;
    padding: var(--size-margin-m);
    flex-direction: column;
    align-items: center;
    gap: var(--size-margin-m);

    /* style */
    border-radius: 8px;
    border: 1px solid var(--audette-gray-300);
    background: var(--audette-white);
}

.full-width-chart-modal {
    /* layout */
    display: flex;
    width: 80%;
    padding: var(--size-margin-m);
    flex-direction: column;
    align-items: center;
    gap: var(--size-margin-m);
    flex-shrink: 0;

    /* position */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* styling */
    background-color: var(--audette-white);
    border-radius: 8px;
    border: 1px solid var(--audette-gray-300);
}

.chart-loading-skeleton {
    align-items: flex-start;
}

.chart-loading-skeleton--title {
    border-radius: 20px;
    height: 1rem;
    width: 40%;
}

.chart-loading-skeleton--body {
    height: 100%;
    width: 100%;
}
