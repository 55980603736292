.reports-page {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: auto;
    height: 100%;


    .report {
        padding: var(--size-margin-l) 0;
        background-color: var(--audette-gray-100);
        border-top: 1px solid var(--audette-gray-300);

        display: flex;
        flex-basis: 100%;
        flex-direction: column;
    }

}