.table-pagination-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.hidden-column {
    display: none !important;
}

.table {
    overflow: auto;

    th {
        height: 50px;
        padding: 0 12px;
    }

    td {
        padding: 17.5px 12px;

        p {
            line-height: 100%;
        }
    }
}

.settingsTable .table{
    td {
        padding: 17.5px 45px;
    } 
    
    th {
        padding: 0px 45px;
    }

}

.table-pagination {
    flex-shrink: 0;
    align-self: flex-end;
}

.table-row:hover {
    background: #f7f1fe;
}

.MuiTablePagination-selectLabel {
    display: none;
}
