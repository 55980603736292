.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 0;
    overflow: hidden;
    background-color: var(--audette-white);
    box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%);
    transition: width 0.3s ease;
    z-index: var(--z-index-sidebar);
}

.sidebar.hidden > * {
    width: 0;
}

.sidebar.show {
    width: 420px;
}

.sidebar.show.is-expanded {
    width: 80%;
}

.sidebar--header {
    height: 77px;
    border-bottom: 1px solid var(--audette-concrete);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--size-margin-m);
}

.sidebar-expand{
    margin-left: auto;
    margin-right: 20px;
    padding-top: 5px;
}